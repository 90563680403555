import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "How to position the Electron tray window on all platforms",
  "author": "Patrick Passarella",
  "date": "2020-11-07",
  "subtitle": "Creating an Electron tray app and correctly positioning it on Windows, Linux, and Mac",
  "cover": "./cover.jpg",
  "coverCredit": "Aziz Acharki",
  "coverWebsite": "Unsplash",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I've had a hard time trying to have the tray window appear in the correct spot for all platforms, since in Linux the `}<inlineCode parentName="p">{`tray.getBounds()`}</inlineCode>{` doesn't work.
To solve that, we can use the mouse position for Linux, and the `}<inlineCode parentName="p">{`tray.getBounds()`}</inlineCode>{` for windows and mac.`}</p>
    <p>{`First, let's create an Electron app (I will not enter in details here, just a quick step-by-step list). You can also jump directly to the important part, `}<a parentName="p" {...{
        "href": "#creating-the-tray-icon"
      }}>{`here`}</a>{`.`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`$ yarn init`}</inlineCode>{` to create an empty project.`}</li>
      <li parentName="ol">{`Change the `}<inlineCode parentName="li">{`main`}</inlineCode>{` property in the `}<inlineCode parentName="li">{`package.json`}</inlineCode>{` to `}<inlineCode parentName="li">{`main.js`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Create a `}<inlineCode parentName="li">{`main.js`}</inlineCode>{` file in the root of your project.`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`$ yarn add electron --dev`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Add a start script to your package.json, `}<inlineCode parentName="li">{`"start": "electron ."`}</inlineCode></li>
      <li parentName="ol">{`Create an `}<inlineCode parentName="li">{`index.html`}</inlineCode>{` in your project root.`}</li>
    </ol>
    <p>{`In the `}<inlineCode parentName="p">{`main.js`}</inlineCode>{` file, I will be using the Electron default code, found on their `}<a parentName="p" {...{
        "href": "https://www.electronjs.org/docs/tutorial/first-app"
      }}>{`documentation`}</a>{`. With just some adjustments.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const { app, BrowserWindow, Tray } = require('electron');
const path = require('path');

// Keep a global reference of the window object, if you don't, the window will
// be closed automatically when the JavaScript object is garbage collected.
let tray = null;
let window = null;

function createWindow() {
  // Create the browser window.
  window = new BrowserWindow({
    width: 800,
    height: 600,
    webPreferences: {
      nodeIntegration: true,
    },
  });

  // and load the index.html of the app.
  window.loadFile('index.html');

  // Open the DevTools.
  window.webContents.openDevTools({ mode: 'detach' });
}

app.on('ready', () => {
  createWindow();
});

app.on('window-all-closed', () => {
  if (process.platform !== 'darwin') {
    app.quit();
  }
});

app.on('activate', () => {
  // On macOS it's common to re-create a window in the app when the
  // dock icon is clicked and there are no other windows open.
  if (BrowserWindow.getAllWindows().length === 0) {
    createWindow();
  }
});
`}</code></pre>
    <p>{`In the `}<inlineCode parentName="p">{`index.html`}</inlineCode>{`, I will also be using the same code as the Electron documentation (just a "Hello World").`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!DOCTYPE html>
<html>
  <head>
    <meta charset="UTF-8" />
    <title>Hello World!</title>
    <meta
      http-equiv="Content-Security-Policy"
      content="script-src 'self' 'unsafe-inline';"
    />
  </head>
  <body>
    <h1>Hello World!</h1>
  </body>
</html>
`}</code></pre>
    <h2>{`Creating the tray icon`}</h2>
    <p>{`Now, let's create a tray icon. In the `}<inlineCode parentName="p">{`main.js`}</inlineCode>{` file, create a function `}<inlineCode parentName="p">{`createTray`}</inlineCode>{`. Remember to import `}<inlineCode parentName="p">{`Tray`}</inlineCode>{` from `}<inlineCode parentName="p">{`'electron'`}</inlineCode>{`.
This function will create the tray with the icon image provided in the assets folder, so you need to create that folder and put the image inside.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const createTray = () => {
  tray = new Tray(path.join(__dirname, 'assets', 'icon.png'));
};
`}</code></pre>
    <p>{`Call this function only after the app is ready.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`app.on('ready', () => {
  createWindow();
  createTray();
});
`}</code></pre>
    <p>{`Run the app again with `}<inlineCode parentName="p">{`$ yarn start`}</inlineCode>{`, you will see that the tray will appear with your icon.`}</p>
    <h2>{`Positioning the tray window`}</h2>
    <p>{`Now for the main part, clicking the tray icon to open the window in the correct position.`}<br parentName="p"></br>{`
`}{`Change the `}<inlineCode parentName="p">{`show`}</inlineCode>{` option to false in the `}<inlineCode parentName="p">{`BrowserWindow`}</inlineCode>{`, to not show the window after created.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const window = new BrowserWindow({
  width: 800,
  height: 600,
  show: false, // <- here
  webPreferences: {
    nodeIntegration: true,
  },
});
`}</code></pre>
    <p>{`Create a function to toggle the window. So whenever we click the tray icon, it will show the window if it's hidden, and hide if it's not.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const toggleWindow = () => {
  if (window.isVisible()) return window.hide();
  return showWindow();
};
`}</code></pre>
    <p>{`That `}<inlineCode parentName="p">{`showWindow`}</inlineCode>{` function, which is being used inside the `}<inlineCode parentName="p">{`toggleWindow`}</inlineCode>{`, is the function that will correctly position the window before showing it. For that, we will be using the library `}<a parentName="p" {...{
        "href": "https://github.com/pixtron/electron-traywindow-positioner"
      }}>{`electron-traywindow-positioner`}</a>{`, which has everything built in to position the tray icon for all platforms, using the mouse position for Linux, and the tray bounds for windows and mac.`}</p>
    <p>{`Install the library `}<inlineCode parentName="p">{`$ yarn add electron-traywindow-positioner`}</inlineCode>{` and import it. After calling the `}<inlineCode parentName="p">{`positioner.position`}</inlineCode>{`, it will change the position of the window, so you can just show it using `}<inlineCode parentName="p">{`window.show()`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const positioner = require('electron-traywindow-positioner');

const showWindow = () => {
  positioner.position(window, tray.getBounds());
  window.show();
};
`}</code></pre>
    <p>{`Then, add a click handler to the tray, which will call that function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const createTray = () => {
  tray = new Tray(path.join(__dirname, 'assets', 'icon.png'));
  tray.on('click', () => {
    toggleWindow();
  });
};
`}</code></pre>
    <p>{`Now, every time you click the tray icon, it will toggle the window, and with the correct position!`}<br parentName="p"></br>{`
`}{`Here is the full code in the `}<inlineCode parentName="p">{`main.js`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const { app, BrowserWindow, Tray } = require('electron');
const positioner = require('electron-traywindow-positioner');
const path = require('path');

let window = null;
let tray = null;

const showWindow = () => {
  positioner.position(window, tray.getBounds());
  window.show();
};

const toggleWindow = () => {
  if (window.isVisible()) return window.hide();
  return showWindow();
};

const createTray = () => {
  tray = new Tray(path.join(__dirname, 'assets', 'drop.png'));
  tray.on('click', () => {
    toggleWindow();
  });
};

const createWindow = () => {
  // Create the browser window.
  window = new BrowserWindow({
    width: 800,
    height: 600,
    show: false,
    webPreferences: {
      nodeIntegration: true,
    },
  });

  // and load the index.html of the app.
  window.loadFile('index.html');

  // Open the DevTools.
  window.webContents.openDevTools({ mode: 'detach' });
};

app.on('ready', () => {
  createWindow();
  createTray();
});

app.on('window-all-closed', () => {
  if (process.platform !== 'darwin') {
    app.quit();
  }
});

app.on('activate', () => {
  // On macOS it's common to re-create a window in the app when the
  // dock icon is clicked and there are no other windows open.
  if (BrowserWindow.getAllWindows().length === 0) {
    createWindow();
  }
});
`}</code></pre>
    <p>{`That's it, very simple, but it's something that took me some time to find a great and simple solution.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      